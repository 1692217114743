import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';

function StockByStore(props) {
  const [categories, setCategories] = useState([]);
  const [warehouses, setWareHouses] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedWareHouse, setSelectedWareHouse] = useState(null);
  const [selectedWareHouseTo, setSelectedWareHouseTo] = useState(null);
  const [products, setProducts] = useState([]);
  const [stock, setStock] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [addedQuantity, setAddedQuantity] = useState(0);
  const [addedDescription, setAddedDescription] = useState(null);
  const [addedId, setAddedId] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [isTransfert, setIsTransfert] = useState(null);

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    return `${year}${month}${day}${hours}${minutes}${seconds}`;
  };
  // Fetch categories and warehouses on component mount
  useEffect(() => {
    const fetchCategories = async () => {
      const token = sessionStorage.getItem('token');
      try {
        const response = await axios.get(
          'https://compta.le-celadon.ma/api/index.php/categories?sortfield=t.rowid&sortorder=ASC&limit=100',
          {
            headers: {
              DOLAPIKEY: token,
              'Content-Type': 'application/json',
            },
          }
        );
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
      try {
        const response = await axios.get(
          'https://compta.le-celadon.ma/api/index.php/warehouses',
          {
            headers: {
              DOLAPIKEY: token,
              'Content-Type': 'application/json',
            },
          }
        );
        setWareHouses(response.data);
      } catch (error) {
        console.error('Error fetching warehouses:', error);
      }

    };

    fetchCategories();
  }, []); // Runs once when the component mounts

  // Fetch products when a category is selected
  useEffect(() => {
    if (selectedCategory) {
      const fetchProducts = async () => {
        const token = sessionStorage.getItem('token');
        try {
          const response = await axios.get(
            `https://compta.le-celadon.ma/api/index.php/categories/${selectedCategory.value}/objects?type=product`,
            {
              headers: {
                DOLAPIKEY: token,
                'Content-Type': 'application/json',
              },
            }
          );
          setProducts(response.data);
        } catch (error) {
          console.error('Error fetching products:', error);
        }
      };

      fetchProducts();
    } else {
      setProducts([]); // Clear products when no category is selected
    }
  }, [selectedCategory, refresh]); // Runs whenever selectedCategory changes
  const fetchStockData = async () => {
    const token = sessionStorage.getItem('token');
    const stockData = [];

    for (const product of products) {
      try {
        const response = await axios.get(
          `https://compta.le-celadon.ma/api/index.php/products/${product.id}/stock`,
          {
            headers: {
              DOLAPIKEY: token,
              'Content-Type': 'application/json',
            },
          }
        );
        stockData.push({ productId: product.id, ...response.data });
      } catch (error) {
        console.error('Error fetching stock:', error);
      }
    }

    setStock(stockData);
  };

  // Fetch stock data for each product
  useEffect(() => {
    if (products.length > 0) {


      fetchStockData();
    }
  }, [products]); // Runs whenever products change

  const onCategoryChange = (newValue) => {
    setSelectedCategory(newValue);
  };

  const onWareHouseChange = (newValue) => {
    setSelectedWareHouse(newValue.value);
  };
  const onWareHouseChangeTo = (newValue) => {
    setSelectedWareHouseTo(newValue);
  };
  const ShowModalAdd = (product, warehouseId, istransfert = false) => {

    setAddedQuantity(0);
    setShowModal(true);
    setIsTransfert(istransfert);
    setAddedDescription(istransfert ? "Transfert du " + product.label : product.label);
    setAddedId(product.id);
    setSelectedWareHouse(warehouseId)
  };


  // Function to get stock for a specific product
  const getStockForProduct = (productId) => {
    return stock.find((item) => item.productId === productId);
  };
  const AddedFormSubmit = async () => {
    const payload = {
      product_id: addedId,
      warehouse_id: selectedWareHouse,
      qty: addedQuantity,
      movementcode: getCurrentDateTime(), // Code personnalisé ou par défaut
      movementlabel: addedDescription || "DefaultCode", // Description personnalisée

    };
    const token = sessionStorage.getItem('token');
    try {
      const response = await axios.post(
        "https://compta.le-celadon.ma/api/index.php/stockmovements",
        payload,
        {
          headers: {
            DOLAPIKEY: token,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Response data:", response.data);
      setShowModal(false);
      setRefresh(!refresh);
      //fetchStockData();
      alert("Stock movement created successfully!");
    } catch (error) {
      console.error("Error posting stock movement:", error);
      alert("Failed to create stock movement. Please try again.");
    }



  };
  const TransfertFormSubmit = async () => {
    const payloadfrom = {
      product_id: addedId,
      warehouse_id: selectedWareHouse,
      qty: parseInt(addedQuantity) * (-1),
      movementcode: getCurrentDateTime(), // Code personnalisé ou par défaut
      movementlabel: addedDescription || "DefaultCode", // Description personnalisée

    };
    const payloadTo = {
      product_id: addedId,
      warehouse_id: selectedWareHouseTo.value,
      qty: parseInt(addedQuantity),
      movementcode: getCurrentDateTime() + 1, // Code personnalisé ou par défaut
      movementlabel: addedDescription || "DefaultCode", // Description personnalisée

    };
    const token = sessionStorage.getItem('token');
    try {
      let r = false;
      const response = await axios.post(
        "https://compta.le-celadon.ma/api/index.php/stockmovements",
        payloadfrom,
        {
          headers: {
            DOLAPIKEY: token,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Response data:", response.data);
      setShowModal(false);
      const response_ = await axios.post(
        "https://compta.le-celadon.ma/api/index.php/stockmovements",
        payloadTo,
        {
          headers: {
            DOLAPIKEY: token,
            "Content-Type": "application/json",
          },
        }
      );
      setRefresh(!refresh);
      //fetchStockData();
      alert("Stock movement created successfully!");
    } catch (error) {
      console.error("Error posting stock movement:", error);
      alert("Failed to create stock movement. Please try again.");
    }



  };
  const changingPercent = (value,product) => {
    if(value!=0){
    window.open(`${process.env.REACT_APP_DATA_PIC_PATH}/label/?name=${product.label}&price=${Math.ceil((product.price_ttc*(1+(value/100))))}&barcode=${product.barcode}&quantity=3`,'_blank');
    }

  }

  return (
    <div className="container mt-0">
      <div>
        <label htmlFor="categories" className="form-label">Catégories</label>
        <Select
          id="categories"
          options={categories.map((x) => ({ value: x.id, label: x.label }))}
          value={selectedCategory}
          onChange={onCategoryChange}
        />
      </div>

      <div className="mt-4">

        <hr width="100%" />
        <br />
        <h2>Products</h2>
        {products.length > 0 ? (
          <table border="1" className="table table-striped">
            <thead>
              <tr>
                <th>Label</th>
                <th>Barcode</th>
                <th>Action</th>
                <th>Price (TTC)</th>
                <th>Pourcentage</th>
                <th>Stock</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product, index) => {
                const productStock = getStockForProduct(product.id);
                return (
                  <tr key={index}>
                    <td>{product.label}</td>

                    <td>{product?.barcode && <a href={`${process.env.REACT_APP_DATA_PIC_PATH}/label/?name=${product.label}&price=${product.price_ttc}&barcode=${product.barcode}&quantity=3`} target='_blank'>{product.barcode}</a>}</td>
                    <td><button className='btn btn-danger' onClick={() => ShowModalAdd(product, null)}>
                      <i class="fa-solid fa-boxes-packing"></i>
                    </button></td>
                    <td>{Math.round(product.price_ttc)} MAD</td>
                    <td>
                      <select className="form-select" onChange={(e)=>{changingPercent(e.target.value,product)}}>
                        <option value="0" selected>
                          Select percentage
                        </option>
                        {Array.from({ length: 100 }, (_, i) => {
                          const value = (i + 1) * 1;
                          return (
                            <option key={value} value={value}>
                              {value}%
                            </option>
                          );
                        })}

                      </select>
                    </td>
                    <td>

                      {Object.entries(productStock?.stock_warehouses || []).length > 0 && <table className="table table-sm">
                        <thead>
                          <tr>
                            <th>Magasin</th>
                            <th>Stock (Real)</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {productStock &&
                            Object.entries(productStock.stock_warehouses).map(
                              ([warehouseId, warehouseData]) => {
                                const warehouseLabel = warehouses.find(
                                  (w) => parseInt(w.id) == parseInt(warehouseId)
                                )?.label;
                                return (
                                  <tr key={warehouseId}>
                                    <td>{warehouseLabel || 'Unknown Warehouse'}</td>
                                    <td><span class="badge text-bg-success">{warehouseData.real}</span></td>
                                    <td><button className='btn btn-warning mx-1' onClick={() => ShowModalAdd(product, warehouseId)}><i class="fa-solid fa-pen-to-square"></i></button>
                                      <button className='btn btn-primary mx-1' onClick={() => ShowModalAdd(product, warehouseId, true)} ><i class="fa-solid fa-arrow-right-arrow-left"></i></button>
                                    </td>

                                  </tr>
                                );
                              }
                            )}

                        </tbody>
                      </table>}
                      {Object.entries(productStock?.stock_warehouses || []).length == 0 && <button className='btn btn-primary' onClick={() => ShowModalAdd(product, null)}>
                        Ajouter ce produit au Magasin
                      </button>}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p>No products available for the selected category.</p>
        )}
      </div>

      {showModal && (
        <div className="modal modal-md fade show d-block" id="yourModal" tabIndex="-1" role="dialog" aria-labelledby="yourModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content bg-warning">
              <div className="modal-header">
                <h5 className="modal-title" id="yourModalLabel">Ajouter Produit au Stock</h5>
                <button type="button" className="close" onClick={() => setShowModal(false)} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div>
                    <label className='form-label'>Quantité à ajouter</label>
                    <input type='number' value={addedQuantity} onChange={e => setAddedQuantity(e.target.value)} className='form-control' />
                  </div>
                  <div>
                    <label className='form-label'>Description</label>
                    <input type='text' value={addedDescription} onChange={e => setAddedDescription(e.target.value)} className='form-control' />
                  </div>
                  {!isTransfert && <>
                    {selectedWareHouse && <div>
                      <label htmlFor="warehouses" className="form-label">Entrepot</label>
                      <input type='text' className='form-control' value={warehouses.find(x => x.id == selectedWareHouse)?.label} />
                    </div>}
                    {!selectedWareHouse && <div>
                      <label htmlFor="warehouses" className="form-label">Magasin de stock</label>
                      <Select
                        id="warehouse"
                        options={warehouses.map((x) => ({ value: x.id, label: x.label }))}
                        value={selectedWareHouse}
                        onChange={onWareHouseChange}
                      />
                    </div>}



                  </>}

                  {isTransfert && <>
                    <div>
                      <label htmlFor="warehouses" className="form-label">Entrepot</label>
                      <input type='text' className='form-control' value={warehouses.find(x => x.id == selectedWareHouse)?.label} />
                    </div>
                    <div>
                      <label for="warehouseto" className='form-label'>Transferé à :</label>
                      <Select
                        id="warehouseto"
                        options={warehouses.filter(x => x.id != selectedWareHouse).map((x) => ({ value: x.id, label: x.label }))}
                        value={selectedWareHouseTo}
                        onChange={onWareHouseChangeTo}
                      />
                    </div>
                  </>}


                </form>
              </div>
              <div className="modal-footer">
                <button className='btn btn-lg btn-primary' onClick={isTransfert ? TransfertFormSubmit : AddedFormSubmit}>{!isTransfert ? 'Ajouter' : 'Transferer'}</button>
                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default StockByStore;
