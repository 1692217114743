import {useState,useEffect} from 'react';
import axios from 'axios';


const CarrefourEcommerce = ({ selectedTag ,selectedDefaultWarehouse}) => {
    const [products, setProducts] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => {
      setShowModal(true);
    };
  
    const handleCloseModal = () => {
      setShowModal(false);
    };
    const [formData, setFormData] = useState({
        productName: '',
        file: null,
      });
    
      const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [id]: value,
        }));
      };
    
      const handleFileChange = (e) => {
        setFormData((prevData) => ({
          ...prevData,
          file: e.target.files[0],
        }));
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form Data:', formData);
      };
    const [warehouses, setWareHouses] = useState([]);
    useEffect(() => {
        const fetchCategories = async () => {
            const token = sessionStorage.getItem('token');

            try {
                const response = await axios.get(
                    'https://compta.le-celadon.ma/api/index.php/warehouses',
                    {
                        headers: {
                            DOLAPIKEY: token,
                            'Content-Type': 'application/json',
                        },
                    }
                );
                setWareHouses(response.data);
            } catch (error) {
                console.error('Error fetching warehouses:', error);
            }

        };

        fetchCategories();
    }, []); // Runs once when the component mounts


    useEffect(() => {
        if (selectedTag) {
            const fetchProducts = async () => {
                const token = sessionStorage.getItem('token');
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_CLIENT_1_URL_ERP}api/index.php/categories/${selectedTag}/objects?type=product`,
                        {
                            headers: {
                                DOLAPIKEY: token,
                                'Content-Type': 'application/json',
                            },
                        }
                    );
                    setProducts(response.data);
                } catch (error) {
                    console.error('Error fetching products:', error);
                }
            };

            fetchProducts();
        } else {
            setProducts([]); // Clear products when no category is selected
        }
    }, []);


    return (
        <div>
        <h2>Produits {warehouses.find(x=>x.id==selectedDefaultWarehouse)?.label}</h2>
        {products.length > 0 ? (
            <table border="1" className="table table-striped mt-5">
                <thead className='bg-danger '>
                    <tr>
                        <th>Label</th>
                        <th>Barcode</th>
                        <th>Price (TTC)</th>
                        <th>Ajouter un produit</th>
                       
                    </tr>
                </thead>
                <tbody>
                    {products.map((product, index) => {
                    
                        return (
                            <tr>
                            <td>{product.label}</td>
                            <td>{product.barcode}</td>
                            <td><strong>{Math.round(product.price_ttc)}</strong> DHS</td>
                            <td><button className='btn btn-warning' onClick={handleShowModal}><i class="fa-solid fa-plus"></i></button></td>
                          </tr>
                          
                          
                          
                        )
                    }
                    )
                    }
                </tbody>
            </table>
        ) : <>erreur</>
        }

{showModal && (
        <div
          className="modal fade show"
          style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
          tabIndex="-1"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Ajouter un Produit E-commerce </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleCloseModal}
                ></button>
              </div>
              <div className="modal-body">
              <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label className="form-label" htmlFor="product_name">
          Nom du Produit
        </label>
        <input
          className="form-control"
          type="text"
          id="productName"
          value={formData.productName}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label" htmlFor="file_name">
          Image
        </label>
        <input
          className="form-control"
          type="file"
          id="file"
          onChange={handleFileChange}
        />
      </div>
   
    </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCloseModal}
                >
                  Close
                </button>
                <button type="button" onClick={handleSubmit} className="btn btn-primary">
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

    </div>
    );
};

export default CarrefourEcommerce;