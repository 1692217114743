import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Sidebar(props) {

    const [notallowed, setNotAllowed] = useState(process.env.REACT_APP_NOT_ALLOWED);


    const isAllowed = () => {
        return !notallowed.split(',').includes(sessionStorage.getItem("name"));
    }
    return (
        <nav id="sidebar" className="sidebar js-sidebar">
            <div className="sidebar-content js-simplebar">
                <a className="sidebar-brand" href="index.html">
                    <span className="align-middle">Le Céladon Manager</span>
                </a>

                <ul className="sidebar-nav">


                    {isAllowed() && <>


                        <li className="sidebar-header">
                            Pages
                        </li>

                        <li className="sidebar-item active">
                            <Link className="sidebar-link" to={"/"}>
                                <i className="align-middle" data-feather="sliders"></i> <span className="align-middle">Dashboard</span>
                            </Link>
                        </li>
                        <li className="sidebar-item">
                            <Link className="sidebar-link" to="/cmiExcel">
                                <i className="align-middle" data-feather="user"></i> <span className="align-middle">Cmi Excel</span>
                            </Link>
                        </li>

                        <li className="sidebar-item">
                            <a className="sidebar-link" href="/tracker">
                                <i className="align-middle" data-feather="log-in"></i> <span className="align-middle">Track Vehicules</span>
                            </a>
                        </li>

                        <li className="sidebar-item">
                            <a className="sidebar-link" href="/stockHouse">
                                <i className="align-middle" data-feather="user-plus"></i> <span className="align-middle">Stock By Store</span>
                            </a>
                        </li>
                    </>}




                    <li className="sidebar-item">
                        <a className="sidebar-link" href="/stockhandling">
                            <i className="align-middle" data-feather="book"></i> <span className="align-middle">Gestion des ventes</span>
                        </a>
                    </li>
                  
                    

                    {isAllowed() && <>
                        <li className="sidebar-header">
                            Products
                        </li>
                        <li className="sidebar-item">
                            <a className="sidebar-link" href="/addproductsite">
                                <i className="align-middle" data-feather="square"></i> <span className="align-middle">Ajouter Produit WebSite</span>
                            </a>
                        </li>

                        <li className="sidebar-item">
                            <a className="sidebar-link" href="/productList">
                                <i className="align-middle" data-feather="check-square"></i> <span className="align-middle">Voir les produits</span>
                            </a>
                        </li>

                        <li className="sidebar-item">
                            <a className="sidebar-link" href="/ordersGlovo">
                                <i className="align-middle" data-feather="grid"></i> <span className="align-middle">Cmd. Glovo</span>
                            </a>
                        </li>
                          
                    <li className="sidebar-item">
                        <a className="sidebar-link" href="/carrefourecommerce">
                            <i className="align-middle" data-feather="book"></i> <span className="align-middle">Carrefour Ecommerce</span>
                        </a>
                    </li>


                    </>}


                </ul>


            </div>
        </nav>
    );
}

export default Sidebar;