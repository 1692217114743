import { useState, useEffect } from 'react';
import axios from 'axios';
function StockUserHandle({ selectedTag ,selectedDefaultWarehouse}) {

    const [products, setProducts] = useState([]);
    const [stock, setStock] = useState([]);
    const [warehouses, setWareHouses] = useState([]);
    useEffect(() => {
        const fetchCategories = async () => {
            const token = sessionStorage.getItem('token');

            try {
                const response = await axios.get(
                    'https://compta.le-celadon.ma/api/index.php/warehouses',
                    {
                        headers: {
                            DOLAPIKEY: token,
                            'Content-Type': 'application/json',
                        },
                    }
                );
                setWareHouses(response.data);
            } catch (error) {
                console.error('Error fetching warehouses:', error);
            }

        };

        fetchCategories();
    }, []); // Runs once when the component mounts

    const fetchStockData = async () => {
        const token = sessionStorage.getItem('token');
        const stockData = [];

        for (const product of products) {
            try {
                const response = await axios.get(
                    `https://compta.le-celadon.ma/api/index.php/products/${product.id}/stock`,
                    {
                        headers: {
                            DOLAPIKEY: token,
                            'Content-Type': 'application/json',
                        },
                    }
                );
                stockData.push({ productId: product.id, ...response.data });
            } catch (error) {
                console.error('Error fetching stock:', error);
            }
        }

        setStock(stockData);
    };
    useEffect(() => {
        if (selectedTag) {
            const fetchProducts = async () => {
                const token = sessionStorage.getItem('token');
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_CLIENT_1_URL_ERP}api/index.php/categories/${selectedTag}/objects?type=product`,
                        {
                            headers: {
                                DOLAPIKEY: token,
                                'Content-Type': 'application/json',
                            },
                        }
                    );
                    setProducts(response.data);
                } catch (error) {
                    console.error('Error fetching products:', error);
                }
            };

            fetchProducts();
        } else {
            setProducts([]); // Clear products when no category is selected
        }
    }, []);

    useEffect(() => {
        if (products.length > 0) {


            fetchStockData();
        }
    }, [products]);
    const getStockForProduct = (productId) => {
        return stock.find((item) => item.productId === productId);
    };

    return (
        <div>
            <h2>Produits {warehouses.find(x=>x.id==selectedDefaultWarehouse)?.label}</h2>
            {products.length > 0 ? (
                <table border="1" className="table table-striped">
                    <thead className='bg-primary '>
                        <tr>
                            <th>Label</th>
                            <th>Barcode</th>

                            <th>Price (TTC)</th>
                            <th>Stock</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {products.map((product, index) => {
                            const productStock = getStockForProduct(product.id);
                            return (
                                <tr>
                                <td>{product.label}</td>
                                <td>{product.barcode}</td>
                                <td>{Math.round(product.price_ttc)}</td>
                                <td>
                                  { productStock && productStock?.stock_warehouses && Object.entries(productStock?.stock_warehouses).length > 0 ? (
                                    Object.entries(productStock?.stock_warehouses)
                                      .filter(([warehouseId]) => warehouseId == String(selectedDefaultWarehouse)) // Ensure matching types
                                      .map(([warehouseId, warehouseData]) => (
                                        <div key={warehouseId}>{warehouseData.real}</div>
                                      ))
                                  ) : (
                                    <span>Aucun stock disponible</span>
                                  )}
                                </td>
                                <td>
                                    {productStock &&  Object.entries(productStock?.stock_warehouses)
                                      .filter(([warehouseId]) => warehouseId == String(selectedDefaultWarehouse)).length>0 && <>
                                      Vendu
                                      </>}
                                </td>
                              </tr>
                              
                              
                              
                            )
                        }
                        )
                        }
                    </tbody>
                </table>
            ) : <>erreur</>
            }



        </div>
    );
}

export default StockUserHandle;